/*You can add global styles to this file, and also import other style files */
/* @import "~ng-pick-datetime/assets/style/picker.min.css"; */
@import "~bootstrap-icons/font/bootstrap-icons.css";


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700;800&display=swap');

:root {
  --canvas-header-underline: rgba(95, 68, 206, 1);
  --canvas-header-underline-height: 7px;
}

* {
  font-family: 'Poppins', sans-serif;
}

.hr-onsite {
  padding: 0 !important;
  margin: 0 !important;
  color: ebebf2 !important;
}

.onsite-shadow {
  box-shadow: 0 2px 25px #9090901f;
}

.onsite-shadow-hover:hover {
  box-shadow: 0 2px 25px #9090901f;
}

.bg-p1,
.btn-p1 {
  background-color: #6B3EEB !important;
}

.bg-p2,
.btn-p2 {
  background-color: #1A0C52 !important;
}

.bg-p3,
.btn-p3 {
  background-color: #E5E7F3 !important;
}

.bg-p4 {
  background-color: #E5B300;
}

.text-p1 {
  color: #6B3EEB !important;
}

.text-p2 {
  color: #1A0C52 !important;
}

.text-p3 {
  color: #E5E7F3 !important;
}

.secondary-font {
  color: #898DA3;
}

.text-p4 {
  color: #361AA9 !important;
}

/* Text sizing for extra small */
.fs-7 {
  font-size: 0.8rem !important;
}

.f-10 {
  font-size: 10px;
}

.fs-8 {
  font-size: 0.75rem !important;
}

.f-transaction-status {
  font-size: 9px;
}

.f-18 {
  font-size: 18px;
}

.f-16 {
  font-size: 16px;
}

.fw-500{
  font-weight: 500;
}
/* This is for removing blue outline from input, button & other in bootstrap 5 */

input[type="text"],
input[type="text"]:focus,
input[type="email"],
input[type="file"],
input[type="checkbox"],
input[type="submit"],
input[type="password"],
input[type="number"],
:checked,
textarea,
select,
button {
  outline: none !important;
  box-shadow: none !important;

  color: #1A0C52;
}

.input-height {
  height: 52px;
}

input[type=file]::file-selector-button {
  border: none;
  font-size: 0.8rem;
  background-color: #0d6efd;
  color: #fff;
  transition: 1s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Primary-Color: #5F44CE */
.onsite-bg-primary,
.onsite-btn-primary {
  background: #5F44CE !important;
}

.onsite-bg-primary-dark,
.onsite-btn-primary-dark {
  background: #1A0C52 !important;
}

.onsite-bg-danger,
.onsite-btn-danger {
  background: #DC1717 !important;
}

.onsite-bg-success,
.onsite-btn-success {
  background: #248B00 !important;
}

.onsite-bg-secondary,
.onsite-btn-secondary {
  background: rgba(97, 99, 111, 0.2) !important;
  cursor: pointer;
}

.onsite-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  height: 38px;
  border-radius: 8px;
  border: 1px solid #E5E7F3;
}

.onsite-btn-sm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  border-radius: 8px;
  border: 1px solid #E5E7F3;
}

.onsite-btn:hover {
  opacity: 0.8;
}

.btn-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FCFCFC;
}



/* Login standalon css */

/* .login{
  padding-left: 15px !important;
  padding-top: 27px !important;
  padding-right: 15px !important;
  padding-bottom: 27px !important;
} */

.text-disable {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.disabled-font {
  color: #61636F;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: #090226;
}

.subtitle {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.bottom {
  box-sizing: border-box;

  position: absolute;
  /* width: 291.5px;
height: 15.8px; */
  /* left: 0px; */
  top: 45.94px;
}

.cursor {
  cursor: pointer;
}

.onsite-login svg {
  position: absolute;
  width: 29px;
  height: 29px;
  top: 92px;
  border: 1px solid #ECE9FA;
  border-radius: 4px;
  transform: rotate(40.5deg);
}

.onsite-login svg:nth-child(1) {
  left: 1244.84px;
}

.onsite-login svg:nth-child(2) {
  left: 1266.84px;
}

.onsite-login svg:nth-child(3) {
  left: 1286.84px;
}

.onsite-login-freestyle svg {
  position: absolute;
  width: 29px;
  height: 29px;
  border: 1px solid #ECE9FA;
  border-radius: 4px;

}

.onsite-login-freestyle svg:nth-child(1) {
  left: 1386.84px;
  top: -10px;
}



.onsite-login-freestyle svg:nth-child(3) {
  right: 100px;
  top: 690px;
}


/* custom ng bootstrap carousel indicator */
.carousel-indicators>button {
  background-color: #3a3ae9 !important;
  border-radius: 10px !important;
  width: 10px !important;
  height: 2px !important;
}

.carousel-indicators>.active {
  background-color: #4B4292 !important;
}

.carousel-inner {
  padding-inline: 20px !important;
  background: rgba(107, 79, 249, 0.21);
  border-radius: 12px;
}

.border-remove-last:nth-last-child(1) {
  border: none !important;
}

.avatar {
  font-size: 11px !important;
  background-color: #2567e1;
  border-radius: 100%;
  color: #ffffff;
  font-weight: 600;
  height: 40px;
  width: 40px;
  text-align: center;
  overflow: hidden;
  margin-left: -5px;
  cursor: pointer;
  outline: 2px solid white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-avatar {
  height: 20px;
  width: 20px;
  font-size: 8px !important;
}

.avatar-admin {
  background-color: #199e8e !important;
}

.avatar-manager {
  background-color: #ea5400 !important;
}

.search-box-outer-div {
  border: 1px solid #E5E7F3;
  box-shadow: 0px 14px 24px rgba(72, 76, 102, 0.19);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 3;
}

.search-box {
  max-height: 250px;
  overflow-y: scroll;
}

.search-box-row:hover {
  background: #F9F8FF;
  border-radius: 8px;
}

*::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #F5F5F5;
  border-radius: 10px !important;
}

*::-webkit-scrollbar {
  border-radius: 10px !important;
  width: 6px;
  height: 5px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #d0c7f7 !important;
  /* border: 2px solid #1A0C52; */
}

.edit-delete-icons {
  height: 21px;
  width: 21px;
}

@media only screen and (max-width:768px) {
  /* *::-webkit-scrollbar{
    height: 3px;
  } */

  .btn-text {
    font-size: 10px;
  }

  .in-router-outlet {
    padding-inline: 22px
  }



  .search_input {
    padding-left: 16px;
  }

  .library-card-row {
    padding-inline: 12px !important;
    margin: 0px !important;
  }

  .avatar {
    font-size: 9px;
    height: 38px;
    width: 38px
  }

  .small-avatar {
    font-size: 8px;
    height: 20px;
    width: 20px;
  }

  .library-card-text {
    font-size: 0.9rem !important;
  }

  .library-default-tag {

    font-size: 0.6rem !important;

    padding: 4px 8px !important;

  }

  .library-default-tag {
    font-weight: 400;
    font-size: 0.5rem;
    line-height: 15px;
    border-radius: 4px;
    padding: 4px 6px;
    color: #1A0C52;
    background: #E5E7F3;
  }

  .abs-popup {
    font-size: 10px !important;
    width: 140px !important;
    right: 20px !important;
  }


  .project_card_title {
    font-size: 12px;

  }

  .edit-delete-icons {
    height: 18px;
    width: 18px;
  }

  .f-22 {
    font-size: 20px !important;
  }

  .f-14 {
    font-size: 12px;

  }

  .fs-8 {
    font-size: 0.70rem !important;
  }

  .f-transaction-status {
    font-size: 8px !important;
  }

  .f-18 {
    font-size: 16px !important;
  }

  .f-16 {
    font-size: 14px !important;
  }



  .date-card {
    width: 42px;
  }

  .date-card-header {
    font-size: 8px;
  }
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-35 {
  width: 35% !important;
}

.w-45 {
  width: 45% !important;
}

.w-55 {
  width: 55% !important;
}

.w-65 {
  width: 65% !important;
}

.progress-bar {
  background-color: #361AA9 !important;
}

.content_box {
  padding: 1px 28px;
  background: #F6F6FD;
}

.ng-bar {
  background-color: #361AA9 !important;
}

.ng-meteor {
  box-shadow: #361AA9 0px 0px 10px, #361AA9 0px 0px 5px !important;
}

.empty_list_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 200px);
}

.empty_list_main_title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
}

.empty_list_main_subtitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-align: center;
  margin-top: 12px;
  color: #61636F;
  margin-bottom: 12px;
}

.empty_list_main_btn {
  line-height: 44px;
  padding: 0 20px;
  background: #5F44CE;
  color: white;
  font-size: 12px;
  margin-top: 12px;
  cursor: pointer;
  border-radius: 12px;
}

.cashbook_list_item {
  padding: 20px;
  display: flex;
  background: white;
  /* box-shadow: 0px 14px 24px #EBEBEE; */
  border: 1px solid #ededf1;
  border-radius: 8px;
  margin-bottom: 10px;
  align-items: flex-start;
  /* justify-content: center; */
  position: relative;
}

.cashbook_sn {
  width: 80px;
  font-size: 12px;
  font-weight: 400;
}

.cashbook_list_item_section {
  flex: 1;
  padding-left: 10px;
}

.cashbook_list_item_text {
  font-size: 12px;
  line-height: 18px;
  color: #090226
}

.center-modal .modal-content {
  margin-top: 0px !important;
}

.cashbook_list_item_btn {
  display: inline-block;
  line-height: 23px;
  padding: 0 10px;
  background: #E5E7F3;
  font-size: 10px;
  color: #1A0C52;
  border-radius: 4px;
  margin-top: 8px;
}

.attendance_list_item {
  background: white;
  border: 1px solid #ededf1;
  border-radius: 8px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.attendance_list_item_top {
  padding: 16px 20px;
  border-bottom: 1px solid #E5E7F3;
  display: flex;
}


/* Global styles */

.side-bar-box {
  width: 248px !important;
  /* height: 100vh; */
  position: fixed;
  padding: 0 !important;
}



.top-bar-box {
  padding: 20px 28px 0px 28px !important;
  height: 64px;
  box-sizing: border-box;
}

@media only screen and (max-width:992px) {
  .top-bar-box {
    padding: 20px 20px 0px 20px !important;
    height: 64px;
    box-sizing: border-box;
  }


}

.content-box {
  padding: 0px;
  margin: 0PX;
  box-sizing: border-box;
  background: #F6F6FD;
}

.in-router-outlet {
  min-height: calc(100vh - 106px) !important;
  border-top: 1px solid #E5E7F3;
  background: transparent;
  padding-inline: 29px;
  padding-top: 13px;
  box-sizing: border-box;

}


.offcanvas {
  background-color: #ffffff !important;
  max-width: 536px !important;
  height: 100vh !important;
  margin-top: 0px !important;
  width: calc(100% + 1px) !important;
}

.open-canvas-above-modal {
  z-index: 1060 !important;
}



.offcanvas-header {
  background-color: #fff;
  display: flexbox;
  flex-direction: row;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #242424;
}

.offcanvas-header .canvas-title {
  text-transform: uppercase;
  font-weight: 500;
}

.offcanvas-cancel {
  padding: 8px 16px;
  width: 75px;
  height: 34px;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FCFCFC;
  flex-shrink: inherit;
}

.offcanvas-save {
  padding: 8px 16px;
  width: 62px;
  height: 34px;
  background: #FCFCFC;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #090226;
}

.offcanvas-attendance-section-1 {
  background: #F9F8FF;
  border-radius: 4px;
  font-weight: 400;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-content: space-between;
}

.offcanvas-attendance-section-1>.key {
  font-size: 12px;
  line-height: 18px;
  color: #61636F;
}

.offcanvas-attendance-section-1>.value {
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #090226;
}

.offcanvas-attendance-section-1>.edit {
  display: flex;
}

.offcanvas-attendance-section-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */


  /* Grays-Color/C3 */

  color: #090226;
  margin: 20px auto;
  gap: 17px;
}

.offcanvas-attendance-section-3 {
  /* Grays-Color/C5 */

  background: #FCFCFC;
  /* Secondary-Color/C1 */

  border: 1px solid #E5E7F3;
  /* Shadow/No-Shadow */

  border-radius: 8px;
  position: relative;
}

.offcanvas-attendance-section-3 .input-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 8px;
  gap: 10px;
  position: absolute;
  width: 99px;
  height: 10px;
  left: 16px;
  top: -10px;
  background: #FDFDFD;
  font-size: 12px;
  line-height: 18px;
  color: #61636F;
}

.input {
  border: none !important;
  margin: 0px 20px;
  width: 80% !important;
  padding: 0px;
}

.input-error {
  border: 1px solid #DC1717 !important;
}

.section-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #61636F;
  margin-bottom: 23px;
}

.offcanvas-attendance-section-4 {
  margin: 20px auto;
  display: block;
}


.offcanvas-attendance-section-5 {
  margin: 20px auto;
  display: block;
}


/* .offcanvas {
  overflow-y: scroll;
} */

.small_white_box_flex {
  background: #FFF;
  border: 1px solid #E5E7F3;
  border-radius: 8px;
  padding: 9px;
  display: flex;
  align-items: center;
}

.callender_arrow {
  width: 6px;
  cursor: pointer;
}

.calender_date {
  width: 44px;
  height: 77px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 9px;
  cursor: pointer;
}

.calender_day {
  font-size: 18px;
  text-align: center;
  color: black;
  font-family: Poppins;
  font-weight: 700;
  margin-bottom: 8px;
  width: 100%;
}

.calender_weekday {
  font-size: 12px;
  text-align: center;
  color: black;
  font-family: Poppins;
  font-weight: 400;
  width: 100%;
}

.margin_top_8 {
  margin: 0;
  margin-top: 8px;
}

.text_align_left {
  text-align: left;
}

.separator {
  height: 45px;
  border-left: 1px solid #DCDCDC;
}

.search_input {
  height: 44px;
  line-height: 44px;
  width: 100%;
  border: 1px solid #E5E7F3;
  border-radius: 8px;
  padding-left: 24px;
  font-size: 12px;
}

.search_absolute_icon {
  position: absolute;
  right: 20px;
  top: calc(50% - 12px);
  height: 24px;
}

.background_onclick_close {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.absolute_pop_up_for_create_new {
  position: absolute;
  top: auto;
  border: 1px solid #E5E7F3;
  border-radius: 8px;
  background-color: white;
  z-index: 1001;
  box-shadow: 0px 14px 24px rgba(72, 76, 102, 0.19)
}

/* style="top: auto;left: auto;right: 0; bottom: ; width: 360px;"
style="top: auto;left: auto;right: 0; bottom: ; width: 360px;" */

.pop_up_option_create_new {
  width: 128px;
  height: 102px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #61636F;
  border-radius: 4px;
  cursor: pointer;
}

.pop_up_option_create_new:hover {
  background: #1A0C52;
  color: white;
}

.pop_up_option_create_new svg {
  fill: none;
  stroke: rgb(97, 99, 111);
}

.pop_up_option_create_new:hover svg {
  stroke: rgb(252, 252, 252) !important;
}

.pop_up_option_text {
  font-size: 12px;
  line-height: 18px;
  height: 36px;
  margin-top: 12px;
  color: inherit;
  text-align: center;
}

.option_seperator_verticle {
  border-left: 1px solid #DCDCDC;
}

.option_seperator_horizontal {
  border-bottom: 1px solid #DCDCDC;
}

.flex_box {
  display: flex;
}

.flex_align_center {
  align-items: center;
}

.flex_justify_center {
  justify-content: center;
}

.flex_justify_space_between {
  justify-content: space-between;
}

.filtered_drop_down_1 {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  background: #FCFCFC;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #E5E7F3;
  cursor: pointer;
  color: #61636F;
}

.filter_button {
  color: #61636F;
  font-size: 14px;
  cursor: pointer;
  border: 1.00361px solid rgba(0, 0, 0, 0.1);
  border-radius: 8.02885px;
  padding: 8px 16px;
  transition: background 100ms;
  /* background-color: #fff; */
}

/* .filter_button:hover{
  background: rgba(0, 0, 0, 0.1);
} */

.active_filter_buttom {
  background: #1A0C52;
  color: white;
  padding: 5px 10px;
  display: flex;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-67 {
  margin-top: 67px;
}

.mt-40 {
  margin-top: 40px
}

.p-18 {
  padding: 18px;
}

.p-0-10 {
  padding: 0 10px;
}

.p-10-25 {
  padding: 10px 25px;
}

.p-12 {
  padding: 12px;
}

.p-12-18 {
  padding: 12px 18px;
}

.min_width_110 {
  min-width: 110px;
}

.p-14 {
  padding: 14px;
}

.p-22 {
  padding: 22px;
}

.p-20 {
  padding: 20px;
}

.p-19-24 {
  padding: 19px 24px;
}

.p-40-30 {
  padding: 40px 30px;
}

.p-24-0 {
  padding: 24px 0;
}

.tags {
  border: 1px solid #E5E7F3;
  padding: 0 8px;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
  color: #61636F;
  font-size: 10px;
  line-height: 23px;
  border-radius: 4px;
  cursor: pointer;
}

.active_tag {
  border: 1px solid #61636F;
  background-color: #F1F1F1;
  padding-left: 20px;
  background: url(/../assets/icons/right_tick.svg);
  background-position: 2px center;
  background-repeat: no-repeat;
}

.drop_down_option_text {
  line-height: 23px;
  text-align: center;
  font-size: 12px;
  color: #090226;
  cursor: pointer;
}

.f-12 {
  font-size: 12px;
}

.f-10 {
  font-size: 10px;
}

.f-14 {
  font-size: 13px;
  font-weight: 500;
}

.f-22 {
  font-size: 22px;
}

.f_bold {
  font-weight: bold;
}

.f_c_primary {
  color: #1A0C52;
}

.f_c_black1 {
  color: #61636F;
}

.f_c_black2 {
  color: #090226
}

.f_c_success {
  color: #248B00;
}

.f_c_danger {
  color: #DC1717
}

.f_c_white {
  color: white
}

.f_c_white_2 {
  color: #FCFCFC
}

.bg-purple {
  background-color: #5F44CE;
}

.bg-purple2 {
  background-color: rgba(123, 97, 255, 0.1);
}

.bg-grey {
  background-color: #F1F1F1;
}

.bg-white {
  background-color: white;
}

.no-scroll {
  overflow: hidden;
}

.cicular_indicator {
  width: 23px;
  height: 23px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_tag {
  background: rgba(220, 23, 23, 0.2);
  line-height: 22px;
  border-radius: 4px;
  padding: 5px 8px;
  color: #DC1717;
  /* border: 1px solid #DC1717; */
  font-size: 10px;
}

.payment_tag_success {
  background-color: rgba(36, 139, 0, 0.2);
  /* border-color: #248B00; */
  color: #248B00;
}


.onsite-border-all {
  border: 1px solid;
  border-color: #ebebf2;
}

.onsite-border-top {
  border-top: 1px solid;
  border-color: #ebebf2;
}

.onsite-border-left {
  border-left: 1px solid;
  border-color: #ebebf2;
}

.onsite-border-right {
  border-right: 1px solid;
  border-color: #ebebf2;
}

.onsite-border-bottom {
  border-bottom: 1px solid;
  border-color: #ebebf2;
}

.curve {
  border-radius: 6px;
}


.circular_check {
  width: 22px;
  height: 22px;
  background: #F1F1F1;
  border-radius: 50%;
  margin-right: 12px;
  border: 1px solid #989BB1;
}

.circular_check_active {
  border: 4px solid #F1F1F1;
  background: #5F44CE;
}

.pop_up_img_outer_container {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  overflow: hidden;
  border-radius: 4px;
}

.img_abs_close_icon {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  top: 5px;
  right: 5px;
  background: #F1F1F1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_note_btn {
  font-size: 12px;
  border-bottom: 1px solid #61636F;
}

.note_component {
  border: 1px solid #5F44CE;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  width: 100%;
  position: relative;
}

.note_component_abs_title {
  position: absolute;
  top: -11px;
  line-height: 22px;
  background: white;
  padding: 0 8px;
  font-size: 12px;
  color: #5F44CE;
  left: 20px;
}

/* global modal styling */
.modal-content {
  outline: none !important;
  border: none;
}

.topbar-btn {
  /* margin-right: 35px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 5px;
  height: 44px;
  border-radius: 8px;
  border: none !important;
  background-color: transparent;
}

.topbar-btn svg {
  stroke: rgb(97, 99, 111);
}

.topbar-btn:hover .btn-text,
.topbar-btn:hover svg {
  color: #5F44CE !important;
  stroke: rgb(95, 68, 206);
}

.topbar-btn .btn-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #61636F;
}

.project_card_title {
  font-size: 14px;
  color: #090226
}

.absolute_project_card_line {
  width: 4px;
  height: 28px;
  position: absolute;
  top: calc(50% - 14px);
  left: 0;
}

.percent_profile_indicator {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #5F44CE;
  border-radius: 50%;
}

.project_logo {
  width: 48px;
  height: 48px;
  left: 28px;
  top: 20px;
  /* line-height: 42px; */
  text-align: center;
  background: #8D7AF0;
  border-radius: 8px;
  background: url("./assets/svg/project_topbar_icon.svg") no-repeat;
  background-size: cover;
  /* Heading-Bold/H6 */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  /* identical to box height */
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width:576px) {
  .project_logo {
    min-width: 28px;
    min-height: 28px;
  }

  .in-router-outlet {
    padding-inline: 16px
  }



  .library-card-row {
    padding-inline: 5px !important;
    margin: 0px !important;

  }

  .avatar {
    font-size: 8px;
    height: 33px;
    width: 33px;
  }

  .library-card-text {
    font-size: 0.8rem !important;
  }

  .library-default-tag {

    font-size: 0.5rem !important;

    padding: 3px 5px !important;
  }

  .abs-popup {
    font-size: 9px !important;
    width: 130px !important;
    right: 15px !important;
  }

  .cashbook_list_item {
    padding: 16px;
  }

  .project_card_title {
    font-size: 10px;
    color: #090226
  }

  .search_input {
    padding-left: 12px !important;
  }

  .f-22 {
    font-size: 18px !important;
  }

  .f-14 {
    font-size: 10px !important;
  }

  .f-16 {
    font-size: 12px !important;
  }


  .f-12 {
    font-size: 10px !important;
  }

  .fs-7 {
    font-size: 0.55rem !important;
  }

  .fs-8 {
    font-size: 0.60rem !important;
  }

  .f-transaction-status {
    font-size: 7px !important;
  }

  .f-10 {
    font-size: 8px !important;
  }

  .f-18 {
    font-size: 14px !important;
  }

  .edit-delete-icons {
    height: 16px;
    width: 16px;
  }


  .add-trade-btn {
    padding-inline: 8px !important;
  }

}

.project-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #090226;
}

.pointer {
  cursor: pointer;
}


/* calendar style */

.calender .dropdown-menu {
  padding: 10px !important;
}

.dropdown-menu .btn-primary {
  background-color: #5F44CE !important;
  border: none !important;
}

.calender .show {}

.ngb-dp-header {
  background-color: transparent !important;
}

.ngb-dp-weekdays,
.small {
  background-color: transparent !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #757575 !important;
  margin: 5px 7px !important;
}

.ngb-dp-day {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #757575 !important;
  margin: 5px 7px !important;
}

.ngb-dp-week {}

.ngb-dp-week .bg-primary {
  background: #5F44CE !important;
  border-radius: 50px !important;
}

.ngb-dp-week .text-white {
  color: #FCFCFC !important;
}

.ngb-dp-week .btn-light {}

.btn-light:hover {
  background: #E5E7F3 !important;
  border-radius: 50px !important;
}

.ngb-dp-arrow .btn-link {
  color: #5F44CE !important;
}

.ngb-dp-navigation-select {}


.ngb-dp-navigation-select .form-select {
  margin: 5px !important;
  border: none !important;
}

.datepicker-hide-input {
  /* display: none; */
  /* position: absolute; */
  border: none !important;

}

.datepicker-hide-input:focus {
  outline: none;
}

.datepicker-hide-input input {
  border: none;
  outline: none;
  width: 90px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #090226;
  margin: 0px 5px;
}


.add-party-member-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  margin: 15px;
  background: #5F44CE;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FCFCFC;
}

.party-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #090226;
}

.party-mobile {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #61636F;
}

.party-icon {}




/* input drop down section */
/* input drop down section */
/* input drop down section */
.select-abs_close {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.select-abs {
  position: absolute;
  background-color: #FCFCFC;
  width: 100%;
  z-index: 2;
  max-height: 270px;
  overflow-y: scroll;
}

.unit-card:hover {
  background: #F9F8FF;
  border-radius: 8px;
}

.unit-card-item {
  padding: 16px 26px;
}

/* input drop down section close */
/* input drop down section close */
/* input drop down section close */







/* onsite global tab style */


.tabs_container {
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
  background-color: #fff;
  margin: 0;

}

.tabs_container_content {

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* gap: 40px; */
  /* position: absolute; */
  width: inherit !important;
  height: 29px;
  margin-left: 29px;
  /* margin-top: 28px; */
  /* top: 28px;
  left: 29px; */
}

.tabs_container_content .project-report {
  position: absolute;

  height: 30px;
  right: 100px;
  top: -3px;


  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  cursor: pointer;
  /* Accent-Color/C2 */

  background: #248B00;
  border-radius: 8px;


  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */


  /* Grays-Color/C7 */

  color: #F9F8FF;
}

.tabs_container_content .project-setting {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 50px;
  top: 0;
}


.tabs_container .single_tab {
  margin-right: 5px;
  padding-bottom: 2px !important;
  position: relative;
  height: 30px;
  align-items: center;
  display: flex;
  font-style: normal;
  color: #61636F;

}

.active_tab_line {
  width: 100%;
  height: 3px;
  background: #5F44CE;
  position: absolute;
  bottom: -1px;
  right: 0;
  border-radius: 3px 3px 0px 0px;
  z-index: 1;
}

.tabs_container_content .active {
  color: #5F44CE !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.active_tab_line {
  display: none;
}

.active>.active_tab_line {
  display: block;
}

.modal-content {
  margin-top: 12vh;
}




/* custom input css */

.custom-input {
  background: #FCFCFC;
  border: 1px solid #E5E7F3;
  border-radius: 8px;
  position: relative;
  height: 52px;
  margin: 1px 0px;
}

.custom-input label {
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  padding: 0px 8px 15px 8px;
  height: 18px;
  /* gap: 10px; */
  position: absolute;
  height: 10px;
  left: 16px;
  top: -8px;
  background: #FFF;
  font-size: 10px;
  /* line-height: 18px; */
  color: #61636F;
  z-index: 1;
  text-transform: uppercase;
}

.custom-input input {
  border: none !important;
  height: 50px;
  border-radius: 8px;
  width: 100%;
  padding-left: 15px;
}

.custom-input span {
  position: absolute;
  right: 10px;
  top: 10px;
}

.custom-input select {
  border: none !important;
  height: 50px;
  border-radius: 8px;
  width: 100%;
  padding-left: 15px;
}

.custom-input input:focus-visible {
  outline: none;
}

.custom-div {
  background: #FCFCFC;
  border: 1px solid #E5E7F3;
  border-radius: 8px;
  position: relative;
  height: 52px;
  margin: 1px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 16px;
  cursor: pointer;
}

.custom-div label {
  position: absolute;
  left: 16px;
  top: -8px;
  background: #FFF;
  font-size: 10px;
  color: #61636F;
  z-index: 1;
  padding-inline: 8px;
  text-transform: uppercase;

}

/* library module common css */

.top-bar {
  background-color: #fff;
  padding: 9px 29px;
  box-sizing: border-box;
  border-top: 1px solid #E5E7F3;
}

.add-trade-btn {
  background: #5F44CE;
  border-radius: 48px;
  padding: 8px 16px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #FCFCFC;
  cursor: pointer;
}

.add-trade-btn:hover {
  opacity: 0.8;
}

.library-card-header {
  padding: 0px 24px 0px 24px;
  /* font-weight: 700; */
  font-size: 10px;
  line-height: 18px;
  color: #61636F;
}

.library-card-row {
  cursor: pointer;
  padding: 10px 24px;
  margin: 2px 5px;
}

.library-card-text {
  font-size: 1rem;
  line-height: 21px;
  color: #090226;
}

.library-card-sub-text {
  font-size: 12px;
  line-height: 21px;
  color: #61636F;
}

.library-card-row:hover {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
}

.library-default-tag {
  font-weight: 400;
  font-size: 0.6rem;
  line-height: 15px;
  border-radius: 4px;
  padding: 4px 8px;
  color: #1A0C52;
  background: #E5E7F3;
}










/* canvas css */

.canvas-save-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  width: fit-content;
  background: #5F44CE;
  color: #FCFCFC;
  border-radius: 8px;
}

.canvas-cancel-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  width: fit-content;
  border-radius: 8px;
  color: #242424;
}


.table-title {
  font-size: 10px;
  line-height: 18px;
  /* color: #61636F; */
  font-weight: 500;
  color: #a0a2af;
}


.svg-sm {
  width: 16px !important;
}



.download-btn {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  padding: 0px 12px;
  height: 44px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FCFCFC;
  border: none;
}

.download-pdf {
  background: #5F44CE;
}

.download-excel {
  background: #E5B300;
  color: #090226;
}

.table-tile-hover:hover {
  opacity: 0.8;
}



/* my custom checkbox */

.my-checkbox {
  height: 20px;
  width: 20px;
  background: #F1F1F1;
  border: 1px solid #C2C6DB;
  border-radius: 4px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.my-checkbox-active {
  height: 20px;
  width: 20px;

  background: url(./assets/svg/check.svg) #5F44CE;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 5.4px 10.8px rgba(56, 56, 56, 0.18);
  border-radius: 5.4px;
}

.abs-popup {
  position: absolute;
  padding: 5px 1px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #090226;
  width: 180px;
  z-index: 1001;
  text-align: center;
  overflow: hidden;
  background: #FFFFFF;
  right: -62px;
}

/* .modal-content{
  width: 325px !important;
  text-align: center;
} */


.summary-transaction {
  border-radius: 12px;
  width: 100%;
  height: 92px;
}

/* @media only screen and (max-width:768px){
  .summary-transaction{
    width:194px
  }
} */

.summary-title {
  font-weight: 500;
  font-size: 12px;
  /* line-height: 18px; */
  color: rgba(0, 0, 0, 0.6);
}

.summary-value {
  font-weight: 600;
  font-size: 22px;
  /* line-height: 33px; */
  color: #0B1333;
}

.add-materials-btn-outlined {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  margin-block: 15px;
  border: 2px solid #5F44CE;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #5F44CE
}

.add-materials-btn-outlined:hover {
  background-color: #5F44CE;
  color: #ffffff;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px dotted #6B3EEB;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #1A0C52;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

.bank-canvas {
  z-index: 1100;
}

.bank-canvas-backdrop {
  z-index: 1066 !important;
}

.new-badge {
  height: 9.74px;
  width: 25.67px;
  background-color: #D01753;
  color: #fff;
  font-size: 7px;
  border-radius: 4px;
  padding-left: 5px;
  position: absolute;
}

.date-card {
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
  width: 48px;

}

.date-card-header {
  font-weight: 500;
  font-size: 9px;
  color: #FFFFFF;
  padding: 2px 5px;

}

.date-card-body {
  height: 33px;
  padding: 4px 0;
}

/* for payment out */
.payment-out {}

.payment-out .date-card-header {
  background: #D64F7E;
}

.payment-out .date-card-body {
  background: #FFEBF2;
}

/* for payment in */
.payment-in {}

.payment-in .date-card-header {
  background: #479785;
}

.payment-in .date-card-body {
  background: rgba(18, 119, 96, 0.15);
}

/* for sales invoice */
.sales-invoice .date-card-header {
  background-color: #4781EF;
}

.sales-invoice .date-card-body {
  background-color: #E1EAFF;
}

.subcon-expense {}

.subcon-expense .date-card-header {
  background-color: #52487C;
}

.subcon-expense .date-card-body {
  background-color: #E6E7FB;
}

/* for internal payment */
.payment-internal {}

.payment-internal .date-card-header {
  background: #52487C;
}

.payment-internal .date-card-body {
  background: #E6E7FB;
}

/* for internal payment */
.payment-material {}

.payment-material .date-card-header {
  background: #52487C;
}

.payment-material .date-card-body {
  background: #E6E7FB;
}

/* for material transfer*/
.material-transfer-in {}

.material-transfer-in .date-card-header {
  background: #52487C;
}

.material-transfer-in .date-card-body {
  background: #E6E7FB;
}

.material-transfer-out {}

.material-transfer-out .date-card-header {
  background: #D64F7E;
}

.material-transfer-out .date-card-body {
  background: #FFEBF2;
}


.staff-payment .date-card-header {
  background: rgb(132, 126, 149);

}

.staff-payment .date-card-body {
  background: rgb(228, 227, 232);
}

.invoice {
  background: #E3EAFD;
}

.expense {
  background: rgba(230, 231, 251, 0.8);
}

.payment-in {
  background: rgba(219, 235, 231, 0.8);
}

.payment-out {
  background: rgba(255, 235, 242, 0.8);
}

.project-balance {
  background: rgba(242, 242, 242, 0.9);
}

.staff-payment {
  background: rgb(223, 214, 246);
}

.save-download-btn {
  all: unset;
  cursor: pointer;
}

.image-modal-dialog .modal-content {
  margin: 0px !important;
  background-color: transparent;
  overflow: hidden;

}

.caret {
  width: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #fff;
  transition: 0.3s;
}

.caret-rotate {
  transform: rotate(180deg);
}



.dark-backdrop {
  opacity: 0.9 !important;
}

.small-popup {
  right: 0 !important;
  left: auto !important;
  height: 100% !important;
  border-color: transparent !important;
  /* border-radius: 3%; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.small-selector-popup {
  right: 0 !important;
  left: auto !important;
  height: 100dvh !important;
  border-color: transparent !important;
  background-color: #00000059 !important;
  display: flex;
  flex-direction: column-reverse;

}

.sales-invoice-modal-backdropClass {
  z-index: 1002 !important;
}

.sales-invoice-modal-windowClass {
  z-index: 1003 !important;
}

.modal-backdropClass-2 {
  z-index: 1101 !important;
}

.modal-windowClass-2 {
  z-index: 1102 !important;
}

.custom-disable {
  opacity: 0.7;
  pointer-events: none !important;
  color: #61636F;
}


.custom-modal .modal-dialog {
  max-width: fit-content;
  margin: auto;
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }


}





/* body.modal-open{
  overflow: hidden;
} */

/* input[type="number"] {
  border:1pc solid rgb(86, 7, 171)
} */
